<template>
  <div>
    <CardForm :titulo="titleCard" @enviarForm="guardar" :button="true">
      <float-label
        label="Razón Social:"
        clase="col-sm-6 col-12"
        :required="enviado && !$v.institucion.razon_social.required"
        id="institucion"
        placeholder="Razón social"
        type="text"
        classInput="form-control"
        v-model="institucion.razon_social"
      >
      </float-label>
      <float-label label="CUIT:" clase="col-sm-6 col-12"
          id="cuit"
          placeholder="CUIT"
          type="text"
          inputmode="numeric"
          classInput="form-control"
          v-model="institucion.cuit"
          :required="enviado && !$v.institucion.cuit.required"
          :numeric="enviado && !$v.institucion.cuit.numeric"
        >
      </float-label>
      <float-label label="Domicilio:" clase="col-sm-6 col-12"
          id="domicilio"
          placeholder="Domicilio"
          type="text"
          classInput="form-control"
          v-model="institucion.domicilio"
          :required="enviado && !$v.institucion.domicilio.required"
        >
      </float-label>
      <float-label label="Localidad:" clase="col-sm-6 col-12"
          id="localidad"
          placeholder="Localidad"
          type="text"
          classInput="form-control"
          v-model="institucion.localidad"
          :required="enviado && !$v.institucion.localidad.required"
        >
      </float-label>
      <float-label label="Provincia:" clase="col-sm-6 col-12"
          id="provincia"
          placeholder="Provincia"
          type="text"
          classInput="form-control"
          v-model="institucion.provincia"
          :required="enviado && !$v.institucion.provincia.required"
        >
      </float-label>
      <float-label label="Código postal" clase="col-sm-6 col-12"
          id="codigo_postal"
          placeholder="Código postal"
          inputmode="numeric"
          type="text"
          classInput="form-control"
          v-model="institucion.codigo_postal"
          :required="enviado && !$v.institucion.codigo_postal.required"
          :numeric="enviado && !$v.institucion.codigo_postal.numeric"
        >
      </float-label>
      <float-label label="Email:" clase="col-sm-6 col-12"
          id="email"
          placeholder="Email"
          type="email"
          classInput="form-control"
          v-model="institucion.email"
        >
      </float-label>
      <float-label label="Telefono:" clase="col-sm-6 col-12"
          id="telefono"
          placeholder="Telefono"
          type="text"
          classInput="form-control"
          v-model="institucion.telefono"
        >
      </float-label>
      <float-label label="Estado:" clase="col-sm-6 col-12"
          id="estado"
          classInput="form-control"
          placeholder="Estado"
          v-model="institucion.estado"
          :select="true"
        >
          <template slot="options">
            <option selected value="Habilitado">Habilitado</option>
            <option value="Deshabilitado">Deshabilitado</option>
          </template>
      </float-label>
    </CardForm>
  </div>
</template>
<script>
import FloatLabel from "@/components/layouts/FloatLabel";
import CardForm from "@/components/layouts/CardForm";
import Institucion from "@/models/institucion";
import { required, numeric } from "vuelidate/lib/validators";
import Axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "NuevaInstitucion",
  components: {
    FloatLabel,
    CardForm,
  },
  props: {
    iconoTitulo: String,
    titleCard: String,
    toCreateAction: String,
    toCreateText: String,
  },
  validations: {
    institucion: {
      razon_social: { required },
      cuit: { required, numeric },
      domicilio: { required },
      localidad: { required },
      provincia: { required },
      codigo_postal: { required, numeric },
    },
  },
  data() {
    return {
      enviado: false,
      institucion: new Institucion(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        "Habilitado"
      ),
    };
  },
  methods: {
    guardar() {
      this.enviado = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      Axios.post("/institucion", this.institucion)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              icon: "success",
              title: "Éxito",
              text: `${res.data.institucion.razon_social} se guardó con éxito.`,
            });
            this.$router.push({ name: "ListarInstituciones" });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `No se pudo editar`,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${res.data.institucion.razon_social} no se guardó.`,
          });
          console.log(err);
        });
    },
  },
};
</script>
